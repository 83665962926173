import Hero from "./Hero";
import Subhero from "./Subhero";
import Footer from "./Footer";

function Home() {
  return (
    <>
      <Hero />
      <Subhero />
      <Footer />
      </>
  );
}

export default Home;